import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Layout from '../templates/Master'
import { Box, Button, Grid, Select, MenuItem, InputLabel, FormControl, Typography } from '@mui/material'
import { setIsAddNew, setIsEdit } from '../system/redux/reducers/globals'
import InputUnstyled from '@mui/base/InputUnstyled'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import AddEdit from '../components/AddEdit'
import { onSnapshot, query, collection, orderBy } from 'firebase/firestore'
import { StaticImage } from "gatsby-plugin-image"
import { db } from '../system/firebase/index'

const Input = styled(InputUnstyled)(({ theme }) => ({
    'input, textarea': {
        backgroundColor: theme.palette.grey.main,
        border: 'none',
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
        color: theme.palette.grey.dark,
        fontFamily: 'IBM Plex sans',
        fontWeight: 400,
        fontSize: '14px',
        height: '42px',
        margin: ' 0 0 10px',
        outline: 'none',
        padding: '0 12px',
        width: '100%',

        '&::placeholder': {
            color: theme.palette.grey.dark,
        },
    },
}))

const StyledSelect = styled(Select)(({ theme }) => ({
    backgroundColor: theme.palette.grey.main,
    borderRadius: '6px',
    color: theme.palette.grey.dark,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '12px',
    height: '42px',
    margin: ' 0 0 10px',
    width: '100%',
    // black border selector
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    // black border hover selector
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    // blue border selector
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
}))

const ListingsPage = ({ edit, addNew, dispatch }) => {
    const [listings, setListings] = useState([])
    const [filteredListings, setFilteredListings] = useState({
        unfiltered: [], filtered: []
    })
    const [searchTerm, setSearchTerm] = useState('')
    const [sort, setSort] = useState({ sortBy: 'date_added', direction: 'desc' })
    const theme = useTheme()
    const desktopView = useMediaQuery(theme.breakpoints.up('lg'))

    useEffect(() => {
        const q = query(collection(db, 'listings'), orderBy(sort.sortBy, sort.direction))
        onSnapshot(q, (snapshot) => {
            const output = []
            snapshot.docs.map((doc) => {
                return output.push(doc.data())
            })
            if (sort.sortBy === 'date_modified'){
                output.sort((a, b) => {
                    if (!a.date_modified) return 1;
                    if (!b.date_modified) return -1;
                    return b.date_modified.toMillis() - a.date_modified.toMillis();
                });
            }
            setListings(output)
            setFilteredListings({ unfiltered: output })
        })
    }, [sort])
    useEffect(() => {
        if(listings && searchTerm){
            const output = listings.filter(item => item.pdf.toLowerCase().includes(searchTerm))
            setFilteredListings({ ...filteredListings, filtered: output })
            setListings(output)
        }
        if (!searchTerm) {
            setListings(filteredListings.unfiltered)
        }
    }, [searchTerm])

    const handleChange = (e, type) => {
        if(e.target.value === 'Sort By'){
            return
        }
        if (type === 'sortBy'){
            setSort({ ...sort, sortBy: e.target.value })
        } else{
            setSort({ ...sort, direction: e.target.value })
        }
    }
    const handleSearch = (e) => {
        // set the values to lowercase to ignore casing.
        const string = e.target.value.toLowerCase()
        setSearchTerm(string)
    }
    const Select = 
        <FormControl>
            <InputLabel sx={{ mt:-1 }}>
                <Typography variant='ibm'>
                    Sort By
                </Typography>
            </InputLabel>
            <StyledSelect
                onChange={(e) => {handleChange(e, 'sortBy')}}
                fullWidth
                sx={{ width: '100px' }}>
                <MenuItem value={'date_added'} label="Date Added">Date Added</MenuItem>
                <MenuItem value={'date_modified'} label="Date Modified">Date Modified</MenuItem>
                <MenuItem value={'stp'} label='STP'>STP</MenuItem>
                <MenuItem value={'name'} label='Name'>Name</MenuItem>
            </StyledSelect>
        </FormControl>
    return (
        <Layout>
            <Grid container={true} height='100%' justifyContent='space-between' mb={4} width='100%'>
                <Grid container={true} item xs={desktopView ? 2 : 4} flexWrap='nowrap'>
                    <Grid item pb={desktopView? 0 : 2} pr={desktopView? 2 : 0} width={desktopView ? 1/2: '100%'}>
                        <Button onClick={()=>dispatch(setIsAddNew(true))} variant='secondary'>+ Add New</Button>
                    </Grid>
                    <Grid pl={1} item pb={desktopView? 0 : 2} width={desktopView? 1/2 : 2/3}>
                        <a href='/analytics'><Button variant='secondary'>Analytics</Button></a>
                    </Grid>
                    <Grid display={desktopView? 'none' : 'block'} item pl={1}>
                        {Select}
                    </Grid>
                </Grid>
                <Grid
                    container={true}
                    item
                    justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'space-between']}
                    spacing={3}
                    xs={desktopView? 3.5 : 6}>
                    <Grid item xs={4} width='100%' display={desktopView? 'block' : 'none'}>
                        {Select}
                    </Grid>
                    <Grid container={true} item xs={8} width={desktopView? 'auto' : '100%'}>
                        <Grid
                            bgcolor='grey.main'
                            container={true}
                            flexWrap='nowrap'
                            height='42px'
                            justifyContent='space-between'
                            sx={{ borderRadius:'6px' }}>
                            <Grid item xs={10.5}>
                                <Input
                                    onChange={(e) => handleSearch(e)}
                                    autoComplete='off'
                                    fullWidth
                                    placeholder='Search'
                                    type='text'>
                                </Input>
                            </Grid>
                            <Grid alignItems='center' bgcolor='grey.main' container={true} item width={0.3/3} xs={1.5} sx={{
                                borderTopRightRadius:'6px',
                                borderBottomRightRadius:'6px'
                            }}>
                                <StaticImage src="../assets/svg/icn_search.svg" alt="" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                addNew ?
                    <AddEdit type='add'/>
                    :null
            }
            {
                listings ? listings.map((listing, i) => {
                    return (
                        <>
                            { edit===i ? 
                                <AddEdit type='edit' data={listing} />
                                :
                                <Box key={i} p={2} mb={2} onClick={()=>desktopView ? null : dispatch(setIsEdit(i))} sx={{
                                    borderStyle: 'solid',
                                    borderColor: 'grey.darkLite',
                                    borderWidth: '1px',
                                    borderRadius: '6px',
                                    width:'100%'
                                }}>
                                    <Grid container={true} height='100%' width='100%'>
                                        <Grid item xs={desktopView? 1 : 1.3} align='right' pr={3}>
                                            {listing.stp}
                                        </Grid>
                                        <Grid item xs={4} textTransform='uppercase' pl={3} sx={{
                                            borderLeftWidth: '1px',
                                            borderLeftStyle: 'solid',
                                            borderLeftColor: 'grey.darkLite',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}>
                                            {listing.name}
                                        </Grid>
                                        <Grid item xs={3} align='right' textTransform='uppercase' sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}>
                                            {listing.pdf}
                                        </Grid>
                                        <Grid xs={2} align='right'>
                                            Visits: {listing.visits || '0'}
                                        </Grid>
                                        {
                                            desktopView ?
                                                <Grid
                                                    align='right'
                                                    alignItems='center'
                                                    container={true}
                                                    justifyContent='flex-end'
                                                    item
                                                    xs={2}
                                                    onClick={()=>dispatch(setIsEdit(i))}
                                                    sx={{ cursor: 'pointer' }}>
                                                    <StaticImage src="../assets/svg/icn_pen.svg" alt="" />
                                                    <Typography variant='ibm' ml={1}>Edit</Typography>
                                                </Grid>: null
                                        }
                                        
                                    </Grid>
                                </Box>
                            }
                        </>
                    )
                }): null
            }
            <Grid container={true} justifyContent='center' mt={4}>
                <Grid container={true} justifyContent='center' border='1px solid #DBDBDB' width='30px' padding='8px' borderRadius='15px' onClick={()=>dispatch(setIsAddNew(true))} sx={{ cursor: 'pointer' }}>
                    <StaticImage src="../assets/svg/icn_plus.svg" alt="" />
                </Grid>
            </Grid>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        edit: state.Globals.isEdit,
        addNew: state.Globals.isAddNew,
    }
}

export default connect(mapStateToProps)(ListingsPage)

export const Head = () => <title>Listings Page</title>
