import { requestSignature, receiveSignature, signatureError } from '../system/redux/reducers/cloudinary'
const moment = require('moment')
const sha256 = require('js-sha256')

export const generateSignature = (dispatch) => {
    dispatch(requestSignature());
    try {
        const apiKey = process.env.GATSBY_CLOUDINARY_KEY
        const cloudName = process.env.GATSBY_CLOUDINARY_NAME
        const username = process.env.GATSBY_CLOUDINARY_USER
        const timestamp = moment().unix()

        const payload = `cloud_name=${cloudName}&timestamp=${timestamp}&username=${username}${process.env.GATSBY_CLOUDINARY_ID}`
        const signature = sha256(payload)
        const data = JSON.stringify({
            apiKey,
            cloudName,
            signature,
            timestamp,
            username,
        })
        dispatch(receiveSignature(data))
    } catch (error) {
        // console.error(error);
        dispatch(signatureError())
    }
}
