import React, { useState, useEffect } from 'react'
import Analytics from '../modules/Analytics'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Box, Button, Grid, Typography } from '@mui/material'
import { setIsAddNew, setIsEdit } from '../system/redux/reducers/globals'
import { widgetLoading } from '../system/redux/reducers/cloudinary'
import { openDeleteModal } from '../system/redux/reducers/modal'
import { db } from '../system/firebase/index'
import { updateDoc, doc, collection, setDoc, Timestamp, query, onSnapshot, where } from 'firebase/firestore'
import InputUnstyled from '@mui/base/InputUnstyled'
import Loading from '../components/Loading'
import { StaticImage } from "gatsby-plugin-image"
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { generateSignature } from '../helpers/Cloudinary'
import { styled } from '@mui/material/styles'
import NavLink from '../components/NavLink'
import ConfirmModal from '../components/ConfirmModal'

const Input = styled(InputUnstyled)(({ theme, light }) => ({
    'input, textarea': {
        backgroundColor: 'white',
        border: 'none',
        color: theme.palette.black.main,
        fontSize: '16px',
        fontFamily: 'Montserrat',
        fontWeight: light ? 300 : 400,
        margin: ' 0 0 10px',
        outline: 'none',
        padding: '0 12px',
        textTransform: 'uppercase',
        width: '300px',

        '&::placeholder': {
            color: theme.palette.black.main,
            textTransform: 'uppercase'
        },
    },
}))

const AddEdit = ({ dispatch, type, data=[], signature, loading }) => {
    const [dataAna, setData] = useState([]);
    const [analytics, setAnalyticView] = useState(false);
    const [viewerDetails, setViewerDetails] = useState('');
    function convertTimeFromSecondsAndNanoseconds(data) {
        const { seconds, nanoseconds } = data;
        const milliseconds = seconds * 1000 + nanoseconds / 1e6; // convert nanoseconds to milliseconds
        const date = new Date(milliseconds);
        return date.toUTCString();
    }
    
    const convertTimestampToDate = (timestamp) => {
        const milliseconds = (timestamp.seconds * 1000) + (timestamp.nanoseconds / 1000000);
        return new Date(milliseconds);
    };

    useEffect(() => {
        const chartData = {};
        const q = query(collection(db, 'visits'), where('pdf_stp', '==', analytics));
        onSnapshot(q, (snapshot) => {
            const output = [];
            snapshot.docs.forEach((doc) => {
                const data = doc.data();
                const date = convertTimestampToDate(data.timestamp);
                const year = date.getFullYear();
                const company = data.user_company ? data.user_company.toUpperCase() : 'N/A';
                const monthYear = `${new Date(date).toLocaleString('default', { month: 'short' }).toUpperCase()}-${year}`;

                if (!chartData[monthYear]) {
                    chartData[monthYear] = { name: monthYear, SAB: 0, SABM: 0, HEINEKEN: 0, RETAILER: 0, OTHER: 0, 'N/A': 0 };
                }

                chartData[monthYear][company] += 1;

                output.push({
                    company: data.user_company ? data.user_company.toUpperCase() : 'N/A',
                    location: data.geo.city,
                    date: convertTimeFromSecondsAndNanoseconds(data.timestamp),
                });
            });

            // Sort chartData by date
            const sortedChartData = Object.values(chartData).sort((a, b) => {
                const dateA = new Date(a.name.split('-')[1], new Date(Date.parse(a.name.split('-')[0] +" 1, 2012")).getMonth());
                const dateB = new Date(b.name.split('-')[1], new Date(Date.parse(b.name.split('-')[0] +" 1, 2012")).getMonth());
                return dateA - dateB;
            });
            // Sort output by date
            const sortedViewerDetails = output.sort((a, b) => new Date(b.date) - new Date(a.date));
            setData(sortedChartData);
            setViewerDetails(sortedViewerDetails);
        });
    }, [analytics]);

    // States
    const [pdf, setPdf] = useState()
    const [error, setError] = useState(false)
    const [pdfName, setPdfName] = useState()
    const [inputs, setInputs] = useState({
        name: data.name || '',
        stp: data.stp || '',
        pdf: data.pdf || '',
    })
    const theme = useTheme()
    const desktopView = useMediaQuery(theme.breakpoints.up('lg'))

    useEffect(() => {
        setPdfName(pdf? pdf[0].filename : inputs.pdf)
    }, [pdf])
    useEffect(() => {
        if (!signature) {
            generateSignature(dispatch)
        }
    }, [signature])

    // Cloudinary
    const showWidget = () => {
        window.cloudinary.openUploadWidget({
            cloud_name: signature.cloudName,
            username: signature.username,
            uploadPreset: process.env.GATSBY_CLOUDINARY_PDF_PRESET,
            timestamp: signature.timestamp,
            signature: signature.signature,
            multiple: false,
            max_files: 1,
            sources: [ 'local' ],
        }, (error, result) => {
            if (!error && result && result.event === 'success') {
                if(result.info.existing){
                    setError(true)
                }
                else {
                    const updated = []
                    const name = result.info.url.split('\\').pop().split('/').pop()
                    const nameSansExt = name.split('.')[0]
                    const filepath = result.info.public_id.split(nameSansExt)[0]
                    const file = {
                        filename: name,
                        filepath,
                        media_type: result.info.resource_type,
                        public_id: nameSansExt,
                    }
                    updated.push(file)
                    setPdf(updated)
                    dispatch(widgetLoading(false))
                }
            } else if(result.event === 'close') {
                dispatch(widgetLoading(false))
            }
        })
    }

    // Event Handlers
    const handleClick = () => {
        if(type==='edit'){
            dispatch(setIsEdit(false))
        } else { //add
            dispatch(setIsAddNew(false))
        }
    }
    const handleWidget = () => {
        setError(false)
        dispatch(widgetLoading(true))
        if(!loading){
            showWidget()
        }
    }
    const deleteListing = (e) => {
        e.preventDefault()
        dispatch(openDeleteModal(data.docId))
    }

    // Form
    const { register, handleSubmit, formState: { errors }} = useForm()
    const updateInputs = (e, el) => {
        setInputs({ ...inputs, [el]: e.target.value })
    }
    const submitFunction = async () => {
        if(type==='edit'){
            const docRef = doc(db, 'listings', data.docId)
            const updates = { ...inputs }
            updates.pdf = pdfName
            updates.date_modified = Timestamp.now()
            await updateDoc(docRef, updates)
            dispatch(setIsEdit(false))
        } else { //add
            const newDocRef = doc(collection(db, 'listings'))
            inputs.docId = newDocRef.id
            inputs.date_added = Timestamp.now()
            inputs.pdf = pdfName
            setDoc(newDocRef, inputs)
            dispatch(setIsAddNew(false))
        }
    }

    return (
        <>
            {   analytics ?
                <Box pb={2}>
                    <Analytics global={false} setAnalyticView={(view) => setAnalyticView(view)} name={`${inputs.stp} | ${inputs.name}`} data={dataAna} viewerDetails={viewerDetails}/>
                </Box>:
                <Box component="form" onSubmit={handleSubmit(() => {submitFunction()})} my={3} sx={{
                    borderStyle: 'solid',
                    borderColor: 'grey.darkLite',
                    borderWidth: '1px',
                    borderRadius: '4px',
                    width:'100%',
                }}>
                    <Grid bgcolor='black.main' color='white.main' container={true} flexWrap='nowrap' justifyContent='space-between' px={[2, 2, 2, 2, 2, 5]} py={2}>
                        <Grid item xs={desktopView? 1 : 2} width={1/2}>{type==='edit'? 'EDIT' : 'ADD NEW'}</Grid>
                        <Grid
                            container={true}
                            item
                            flexWrap='nowrap'
                            justifyContent={['flex-end', 'flex-end', 'flex-end', 'flex-end', 'space-between']} 
                            spacing={3}
                            xs={desktopView? 2 : 4}>
                            <Grid alignItems='center' container={true} justifyContent='flex-start' item sx={{ cursor: 'pointer' }}>
                                <StaticImage src="../assets/svg/icn_check.svg" alt="" />
                                <Box ml={1}>
                                    <Button variant='submit' type='submit'>Submit</Button>
                                </Box>
                            </Grid>
                            <Grid
                                alignItems='center'
                                justifyContent='flex-end'
                                container={true}
                                fontSize='16px'
                                fontFamily='IBM Plex sans'
                                item
                                onClick={handleClick}
                                sx={{ cursor: 'pointer' }}>
                                <StaticImage src="../assets/svg/icn_close.svg" alt="" />
                                <Typography ml={2}>Cancel</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid bgcolor='white.main' px={[2, 2, 2, 2, 2, 5]} py={4} sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        <Grid container={true} flexWrap='nowrap'>
                            <Box>STP:</Box>
                            <Input
                                {...register(
                                    'stp',
                                    { required: 'Cannot be empty', pattern: {
                                        value: /^(0|[1-9][0-9]*)$/i,
                                        message: 'Please enter a number',
                                    }})}
                                autoComplete='off'
                                name='stp'
                                onChange={(e) => updateInputs(e, 'stp')}
                                placeholder='########'
                                type='text'
                                value={inputs.stp}
                            />
                            { errors && errors.stp && <Box fontSize='12px' color='red.main' fontWeight='bold'>{errors.stp.message}</Box> }
                        </Grid>
                        {
                            type!=='edit'?
                                <Box width={desktopView? 2/3 : '100%'} borderBottom='1px solid #DBDBDB'></Box>:
                                null  
                        }
                        <Grid container={true} flexWrap='nowrap' mt={2}>
                            <Box>NAME:</Box>
                            <Input
                                {...register(
                                    'name',
                                    { required: 'Cannot be empty' })}
                                autoComplete='off'
                                defaultValue={inputs.name}
                                name='name'
                                onChange={(e) => updateInputs(e, 'name')}
                                placeholder='Store/Outlet Name'
                                type='text'
                            />
                            { errors && errors.name && <Box fontSize='12px' color='red.main' fontWeight='bold'>{errors.name.message}</Box> }
                        </Grid>
                        {
                            type!=='edit'?
                                <Box width={desktopView? 2/3 : '100%'} borderBottom='1px solid #DBDBDB'></Box>:
                                null  
                        }
                        {
                            type==='edit' || (pdf && pdf.length) ?
                                <Grid container={true} mb={1} fontWeight={300}>
                                    <Grid container={true} mb={type==='edit'? 0 : 2} mt={2} flexWrap='nowrap'>
                                        <Box>PDF:</Box>
                                        <Box textTransform='uppercase' ml={2} sx={{
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden' }}>
                                            {pdfName}
                                        </Box>
                                    </Grid>
                                </Grid>:
                                null  
                        }
                        {
                            type==='edit' && data.date_modified ?
                                <Grid container={true} flexWrap='nowrap' fontWeight={300}>
                                    <Box>DATE MODIFIED:</Box>
                                    <Box textTransform='uppercase' ml={2} sx={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden' }}>{convertTimeFromSecondsAndNanoseconds(data.date_modified)}</Box>
                                </Grid>:
                                null
                        }
                        {
                            type==='edit' ?
                                <Grid container={true} mb={5} flexWrap='nowrap' fontWeight={300}>
                                    <Box>DATE ADDED:</Box>
                                    <Box textTransform='uppercase' ml={2} sx={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden' }}>{convertTimeFromSecondsAndNanoseconds(data.date_added)}
                                    </Box>
                                </Grid>:
                                null
                        }
                        {
                            error?
                                <Grid alignItems='center' container={true} item>
                                    <StaticImage src="../assets/svg/warning.svg" alt="" />
                                    <Typography color='red.main' ml={1} variant='link'>Failed to upload! A PDF with this name already exisits.</Typography>
                                </Grid>: null
                        }
                        { type==='edit' ?
                            <Grid borderTop='1px solid #DBDBDB' container={true} justifyContent= 'space-between' flexWrap='nowrap' pt={2}>
                                <Grid item={true} container={true} mobile={9} md={4}>
                                    <Grid alignItems='center' container={true} item={true} sx={{ cursor: 'pointer' }} mobile={4} md={3}>
                                        <NavLink to={`/${inputs.stp}`} external>
                                            <Grid alignItems='center' container={true}>
                                                <Typography pr={1} variant='link'>Open PDF</Typography>
                                                <Box display={desktopView? 'block' : 'none'} sx={{ display: 'flex', alignItems: 'center' }}><StaticImage src="../assets/svg/icn_external.svg" alt=""/></Box>
                                            </Grid>
                                        </NavLink>
                                    </Grid>
                                    <Grid alignItems='center' container={true} item={true} sx={{ cursor: 'pointer' }} mobile={4} md={3}>
                                        <Box display={desktopView? 'block' : 'none'} sx={{ display: 'flex', alignItems: 'center' }}><StaticImage src="../assets/svg/icn_plus.svg" alt="" /></Box>
                                        {
                                            typeof window !== 'undefined' && !loading ?(
                                                <Typography pl={1} variant='link' onClick={handleWidget}>Update PDF</Typography>
                                            ):
                                                <Box><Loading color='grey.dark' size='18px'/></Box>
                                        }
                                    </Grid>
                                    <Grid item={true} alignItems='center' pl={1} justifyContent={desktopView? 'flex-start': 'flex-end'} container={true} onClick={() => setAnalyticView(inputs.stp)} sx={{ cursor: 'pointer' }}mobile={4} md={3}>
                                        <Typography variant='link'>Analytics</Typography>
                                    </Grid>
                                </Grid>
                                <Grid alignItems='center' justifyContent='flex-end' container={true} mobile={3} item onClick={(e) => deleteListing(e)} sx={{ cursor: 'pointer' }}>
                                    <Box display={desktopView? 'block' : 'none'} sx={{ display: 'flex', alignItems: 'center' }}><StaticImage src="../assets/svg/icn_trash.svg" alt="" /></Box>
                                    <Typography pl={1} variant='link'>Delete</Typography>
                                </Grid>
                            </Grid>:
                            <Grid alignItems='center' container={true} item mt={6}>
                                <StaticImage src="../assets/svg/icn_plus.svg" alt="" />
                                {
                                    typeof window !== 'undefined' && !loading ? (
                                        <Typography ml={2} variant='link' onClick={handleWidget} sx={{ cursor: 'pointer' }}>Upload PDF</Typography>
                                    ):
                                        <Box ml={2}><Loading color='grey.dark' size='18px'/></Box>
                                }
                            </Grid>
                        }
                        <ConfirmModal data={data} />
                    </Grid>
                </Box>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        edit: state.Globals.isEdit,
        addNew: state.Globals.isAddNew,
        loading: state.Cloudinary.loading,
        signature: state.Cloudinary && state.Cloudinary.signature,
    }
}

export default connect(mapStateToProps)(AddEdit)